<script setup>
const props = defineProps({
  white: Boolean,
  gray: Boolean
})

const color = computed(() => {
  if(props.gray) return "#DDDDDD";
  if(props.white) return "#FFFFFF"
  return '#13A897'
})

</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16699 10.834L7.50033 14.1673L15.8337 5.83398" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
